<template>
  <div class="home">
    <div
      class="backg"
      v-bind:style="{
        backgroundImage: 'url(' + bg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
    >
      <div class="home_top">
        <div class="logo_left">
          <img src="../assets/img/logobg.png" class="logo_left_tu" alt="" />
        </div>
        <div class="logo_right">
          <div class="right_title">忘记密码</div>
          <div class="cont">
            <div>
              <div class="pwd_inp">
                <input
                  class="input_hao"
                  v-model="phoneValue"
                  maxlength="11"
                  type="text"
                  placeholder="请输入手机号"
                />
                <img
                  src="../assets/img/close.png"
                  class="close_tu"
                  @click="close"
                  alt=""
                />
              </div>

              <div class="pwd_inp">
                <input
                  class="input_hao2"
                  v-model="codeValue"
                  type="text"
                  placeholder="请输入收到的验证码"
                />
                <button
                  type="info"
                  v-show="show"
                  @click="getCode"
                  class="getMa"
                >
                  获取验证码
                </button>
                <button type="info" v-show="!show" class="getMa">
                  重新发送({{ count }}s)
                </button>
              </div>
              <div class="pwd_inp">
                <input
                  class="input_hao"
                  v-model="newPwd1"
                  type="text"
                  placeholder="设置新登录密码"
                />
              </div>
              <div class="pwd_inp">
                <input
                  class="input_hao"
                  v-model="newPwd2"
                  type="text"
                  placeholder="确认新登录密码"
                />
              </div>

              <el-button
                type="primary"
                class="btn"
                @click="setPwd"
                :style="{ background: background }"
                round
                >确认</el-button
              >
              <router-link to="/login" class="yiyou"
                >已有账号，立即登录</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVerifyCodeForgetPwd, forgetPwd } from "@network/login";
export default {
  name: "Forget",
  data() {
    return {
      bg: require("@/assets/img/logbg.png"),
      phoneValue: "",
      codeValue: "",
      newPwd1: "",
      newPwd2: "",
      background: "#1578FF",
      checked: false,
      disabled: true,
      show: true,
      count: "",
      timer: null,
      reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      exp: "",
      // code: "",
      verify: "",
    };
  },
  components: {},
  methods: {
    close() {
      this.phoneValue = "";
    },
    setPwd() {
      if (this.newPwd1 != this.newPwd2) {
        this.$message({
          message: "两次密码不一致",
          type: "error",
          offset: 300,
        });
        return false;
      }
      forgetPwd(
        this.phoneValue,
        this.codeValue,
        this.exp,
        this.verify,
        this.newPwd1
      ).then((res) => {
        console.log(res);

        if (res.code != 0) {
          this.$message({
            message: res.msg,
            type: "error",
            offset: 300,
          });
          return false;
        } else {
          this.$message({
            message: res.msg,
            type: "success",
            offset: 300,
          });
          this.$router.replace("/login");
        }
      });
    },
    getCode() {
      if (this.phoneValue == "") {
        this.$message({
          offset: 300,
          message: "请输入手机号",
          type: "error",
        });
        return false;
      }
      if (!this.reg.test(this.phoneValue)) {
        this.$message({
          offset: 300,
          message: "手机号格式不正确",
          type: "error",
        });
        return false;
      }
      const TIME_COUNT = 180;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.codes();
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    codes() {
      getVerifyCodeForgetPwd(this.phoneValue).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            offset: 300,
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message({
            offset: 300,
            message: res.msg,
            type: "error",
          });
        }

        if (res.code !== 0) {
          return false;
        } else {
          this.exp = res.data.exp;
          this.verify = res.data.verify;
          // this.code = res.data.code;
        }
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import "../assets/css/forget.less";
</style>
