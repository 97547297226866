import {request} from './request'
// 密码登录
export function loginPwd(phone,pwd){
    return request({
        method:"post",
        url:'/loginByPwd',
        data:{
            phone:phone,
            password:pwd,
        }
    })
}
// 验证码登录
export function loginPhone(phone,code,exp,verify){
    return request({
        method:"post",
        url:'/loginByPhone',
        data:{
            phone:phone,
            code:code,
            exp:exp,
            verify:verify,
        }
    })
}
// 注册
export function register(phone,names,code,exp,verify,password,invitee){
    return request({
        method:"post",
        url:'/register',
        data:{
            phone:phone,
            name:names,
            code:code,
            exp:exp,
            verify:verify,
            password:password,
            invitee:invitee,
        }
    })
}
// 注册时获取验证码
export function getVerifyCodeRegister(phone){
    return request({
        method:"get",
        url:'/getVerifyCode',
        params:{
            phone:phone,
            type:'register',
        }
    })
}
// 登录时获取验证码
export function getVerifyCodeLogin(phone){
    return request({
        method:"get",
        url:'/getVerifyCode',
        params:{
            phone:phone,
            type:'login',
        }
    })
}
// 忘记密码获取验证码
export function getVerifyCodeForgetPwd(phone){
    return request({
        method:"get",
        url:'/getVerifyCode',
        params:{
            phone:phone,
            type:'forgetPwd',
        }
    })
}
// 忘记密码
export function forgetPwd(phone,code,exp,verify,password){
    return request({
        method:"post",
        url:'/forgetPwd',
        data:{
            phone:phone,
            code:code,
            exp:exp,
            verify:verify,
            password:password,
        }
    })
}